/*
* Zetta Menu 1.2
* Copyright 2014, nKdev
* 6 June 2014
*/
/*

====================== DEFAULT THEME ======================

*/
/*

====================== MAIN ======================  */

.zetta-menu {
  padding: 0 !important;
  display: inline-block;
  position: relative;
  margin:0 !important;
  text-align:center;

}
.zetta-menu:before,
.zetta-menu ul,
.zetta-menu li,
.zetta-menu div,
.zetta-menu a,
.zetta-menu label,
.zetta-menu input,
.zetta-menu textarea {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
  list-style: none;
}
.zetta-menu a {
  cursor: pointer;
  text-decoration:none;
}
.zetta-menu img{float:left; padding: 8px 0 0 0;}
.zetta-menu h1, .zetta-menu h5, .zetta-menu h6 {font-weight: normal;}
.zetta-menu h2, .zetta-menu h3, .zetta-menu h4{text-align: center;
  margin: 0;
  padding: 15px 0;
  color: #3598dc;
}
.zetta-menu.zm-full-width {
  display: block;
  float:right;
}

.zetta-menu.zm-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.zetta-menu.zm-fixed.zm-position-left {
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
}
.zetta-menu.zm-fixed.zm-position-right {
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
}
.zetta-menu.zm-fixed.zm-position-bottom {
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}
.zetta-menu,
.zetta-menu a,
.zetta-menu a:visited {
  font-size:14px;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: bold !important;
  line-height: 35px !important;
}

.zetta-menu a:hover {
	
	 color: #f69527;

}

.zetta-menu a:hover {
	
	 color: #f69527;

}

.zetta-menu .ativo {
	
	color:#f69527 !important;
	border:1px solid #f69527;

}

.zetta-menu > li:hover > a {
	font-weight: normal;
	line-height: 51px;
	background:none;
}

.zetta-menu > li > a {
  font-weight: normal;
  padding: 0 13px;
  line-height: 51px;
}

.zetta-menu > li:hover .sm{
	color:#FFFFFF;
	font-weight: normal;
	display:block;
	padding:9px 0 !important;
}

.zetta-menu > li:hover .sm:hover{
	color:#dd282c !important;
	font-weight: normal;
	padding:9px 0 !important;
}



.zetta-menu > li {
  display: block;
  vertical-align: middle;
  text-align:center;
  float: left;
  height: 35px;

}
.zetta-menu > li.zm-right-item {
  margin-right: 0;
  float: right;
}

.zetta-menu > li:hover,
.zetta-menu > li.zm-active {

}
.zetta-menu li {
	z-index:999;
  position: relative;
  cursor: default;

}
.zetta-menu li > a {
  text-decoration: none;
  display: block;
}
.zetta-menu li > ul,
.zetta-menu li > div {
  z-index: 1;
}
.zetta-menu.zm-position-left > li,
.zetta-menu.zm-position-right > li {
  position: relative !important;
}
.zetta-menu.zm-position-left > li,
.zetta-menu.zm-position-right > li,
.zetta-menu.zm-position-left > li.zm-logo,
.zetta-menu.zm-position-right > li.zm-logo {
  float: none;
}
.zetta-menu.zm-position-left > li > a .zm-caret,
.zetta-menu.zm-position-right > li > a .zm-caret {
  float: right;
}
.zetta-menu .fa {
  font-size: 12pt;
  letter-spacing: 0px;
  line-height: inherit;
}
.zetta-menu .zm-caret {
  letter-spacing: 0;
  float: right;
  margin-left: 15px;
}
.zetta-menu > li > a .zm-caret {
  float: none;
}
.zetta-menu .zm-single-icon {
  letter-spacing: 0;
}
.zetta-menu:after {
  content: '';
  display: block;
  clear: both;
}
/* 

====================== LOGO ======================

*/
.zetta-menu > li.zm-logo {
  float: left;
  margin-left: 0;
  font-size: 0;
}
.zetta-menu > li.zm-logo a {
  padding: 0;
  line-height: 0;
}
.zetta-menu > li.zm-logo img {
  max-width: 100%;
  max-height: 100%;
}
/* 

====================== MENU DROP DOWN ======================

*/
.zetta-menu ul,
.zetta-menu div.zm-multi-column {
  position: absolute;
  display: none;
}
.zetta-menu ul > li {
  min-height: 20px;
  text-align:left;

}

.zetta-menu ul > li a {
  padding: 0 20px;
  line-height: 25px;
}
.zetta-menu > li > ul,
.zetta-menu > li > ul ul,
.zetta-menu .zm-multi-column,
.zetta-menu .zm-multi-column > ul ul {
  width:235px;
  background:#555 !important;
  color: #FFFFFF;
  text-align:center;
  z-index:999999;
}
.zetta-menu > li > ul li > a,
.zetta-menu > li > ul ul li > a,
.zetta-menu .zm-multi-column li > a,
.zetta-menu .zm-multi-column > ul ul li > a,
.zetta-menu > li > ul li > a:visited,
.zetta-menu > li > ul ul li > a:visited,
.zetta-menu .zm-multi-column li > a:visited,
.zetta-menu .zm-multi-column > ul ul li > a:visited {
  color: #FFFFFF;
  text-align:center;
  border-bottom:1px solid #444;
  border-top:1px solid #888;
  font-size:12px;
}
.zetta-menu > li > ul li:hover,
.zetta-menu > li > ul ul li:hover,
.zetta-menu .zm-multi-column li:hover,
.zetta-menu .zm-multi-column > ul ul li:hover {
    background:#AAA !important;
}

.zetta-menu > li > ul li:hover > a,
.zetta-menu > li > ul ul li:hover > a,
.zetta-menu .zm-multi-column li:hover > a,
.zetta-menu .zm-multi-column > ul ul li:hover > a,
.zetta-menu > li > ul li:hover > a:visited,
.zetta-menu > li > ul ul li:hover > a:visited,
.zetta-menu .zm-multi-column li:hover > a:visited,
.zetta-menu .zm-multi-column > ul ul li:hover > a:visited {
  color: #FFF;
 
}
.zetta-menu > li > ul,
.zetta-menu > li > div.zm-multi-column {
  top: 100%;
  left: 0;
}
.zetta-menu > li > ul ul,
.zetta-menu > li > div.zm-multi-column ul {
  top: 0;
  left: 100%;
}
.zetta-menu > li > ul ul.zm-drop-left,
.zetta-menu > li > div.zm-multi-column ul.zm-drop-left {
  left: auto;
  right: 100%;
}
.zetta-menu.zm-position-bottom > li > ul,
.zetta-menu.zm-position-bottom > li > div.zm-multi-column {
  top: auto;
  bottom: 100%;
  left: 0;
}
.zetta-menu.zm-position-bottom > li > ul ul,
.zetta-menu.zm-position-bottom > li > div.zm-multi-column ul {
  top: auto;
  bottom: 0;
  left: 100%;
}
.zetta-menu.zm-position-bottom > li > ul ul.zm-drop-left,
.zetta-menu.zm-position-bottom > li > div.zm-multi-column ul.zm-drop-left {
  left: -100%;
}
.zetta-menu.zm-position-left > li > ul,
.zetta-menu.zm-position-left > li > div.zm-multi-column {
  top: 0;
  left: 100%;
}
.zetta-menu.zm-position-left > li > ul ul.zm-drop-left,
.zetta-menu.zm-position-left > li > div.zm-multi-column ul.zm-drop-left {
  top: 0;
  left: 100%;
}
.zetta-menu.zm-position-right > li > ul,
.zetta-menu.zm-position-right > li > div.zm-multi-column {
  top: 0;
  left: auto;
  right: 100%;
}
.zetta-menu.zm-position-right > li > ul ul.zm-drop-left,
.zetta-menu.zm-position-right > li > div.zm-multi-column ul.zm-drop-left {
  top: 0;
  left: auto;
  right: 100%;
}
.zetta-menu.zm-position-right > li > ul ul,
.zetta-menu.zm-position-right > li > div.zm-multi-column > ul ul {
  top: 0;
  left: auto;
  right: 100%;
}
.zetta-menu li:hover > ul,
.zetta-menu li:hover > div.zm-multi-column,
.zetta-menu li:hover > div.zm-multi-column > ul {
  display: block;
}
.zetta-menu li > div.zm-multi-column > ul,
.zetta-menu.zm-position-bottom li > div.zm-multi-column > ul {
  left: 0;
  float: left;
}
.zetta-menu > li > div.zm-multi-column > ul {
  position: relative;
  display: inline-block;
}
.zetta-menu > li.zm-right-align > ul,
.zetta-menu > li.zm-right-align > div.zm-multi-column {
  left: auto;
  right: 0;
}
.zetta-menu.zm-position-left > li.zm-right-align > ul,
.zetta-menu.zm-position-left > li.zm-right-align > div.zm-multi-column {
  left: 100%;
  right: auto;
}
.zetta-menu.zm-position-left > li.zm-right-align > ul,
.zetta-menu.zm-position-left > li.zm-right-align > div.zm-multi-column {
  right: 100%;
}
/*

====================== CONTENT DROP DOWN ======================

*/
.zetta-menu > li.zm-content-full {
  position: static;
}
.zetta-menu > li.zm-content-full > div {
  width: 100%;
}
.zetta-menu > li.zm-content > div,
.zetta-menu > li.zm-content-full > div {
  position: absolute;
  display: none;
  left: 0;
  padding: 15px;
  background-color:none; 
  color: #354a5f;
}
.zetta-menu > li.zm-content > div ul,
.zetta-menu > li.zm-content-full > div ul {
  display: block;
  position: relative;
  min-width: 0;
}
.zetta-menu > li.zm-content > div ul li a,
.zetta-menu > li.zm-content-full > div ul li a,
.zetta-menu > li.zm-content > div ul li a:visited,
.zetta-menu > li.zm-content-full > div ul li a:visited {
  text-align: center;
  padding: 15px 0;
  line-height: 1;
  color: #354a5f;
}
.zetta-menu > li.zm-content > div ul li:hover,
.zetta-menu > li.zm-content-full > div ul li:hover {
  background:#fff !important;
}
.zetta-menu > li.zm-content > div ul li:hover a,
.zetta-menu > li.zm-content-full > div ul li:hover a,
.zetta-menu > li.zm-content > div ul li:hover a:visited,
.zetta-menu > li.zm-content-full > div ul li:hover a:visited {
  color: #3598dc;
  text-decoration: underline;
}
.zetta-menu > li.zm-content > div img,
.zetta-menu > li.zm-content-full > div img {
  width: 100%;
  margin: 15px 0;
}
.zetta-menu > li.zm-content > div .zm-video,
.zetta-menu > li.zm-content-full > div .zm-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin: 15px 0;
  overflow: hidden;
}
.zetta-menu > li.zm-content > div .zm-video > iframe,
.zetta-menu > li.zm-content-full > div .zm-video > iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.zetta-menu > li.zm-content > div input,
.zetta-menu > li.zm-content-full > div input,
.zetta-menu > li.zm-content > div textarea,
.zetta-menu > li.zm-content-full > div textarea {
  width: 100%;
  margin-top: 10px;
  font: inherit;
  resize: none;
  padding: 15px;
  background-color:none; 
  color: #354a5f;
}
.zetta-menu > li.zm-content > div input.zm-button,
.zetta-menu > li.zm-content-full > div input.zm-button {
  cursor: pointer;
  font-weight: normal;
  background-color:none; 
  color: #ffffff;
}
.zetta-menu > li.zm-content:hover > div,
.zetta-menu > li.zm-content-full:hover > div {
  display: block;
}
.zetta-menu > li.zm-content > div,
.zetta-menu > li.zm-content-full > div {
  top: 100%;
}
.zetta-menu.zm-position-bottom > li.zm-content > div,
.zetta-menu.zm-position-bottom > li.zm-content-full > div {
  top: auto;
  bottom: 100%;
}
.zetta-menu.zm-position-left > li.zm-content > div,
.zetta-menu.zm-position-left > li.zm-content-full > div {
  top: 0;
  left: 100%;
}
.zetta-menu.zm-position-right > li.zm-content > div,
.zetta-menu.zm-position-right > li.zm-content-full > div {
  top: 0;
  right: 100%;
  left: auto;
}
.zetta-menu > li.zm-content.zm-right-align > div,
.zetta-menu > li.zm-content-full.zm-right-align > div {
  left: auto;
  right: 0;
}
.zetta-menu.zm-position-left > li.zm-content.zm-right-align > div,
.zetta-menu.zm-position-left > li.zm-content-full.zm-right-align > div {
  left: 100%;
  right: auto;
}
/*

====================== GRID SYSTEM ======================

*/
.zetta-menu .zm-row {
  display: block;
}
.zetta-menu .zm-row .zm-col {
  display: inline-block;
  margin-bottom: 5px;
  padding: 5px;
  vertical-align: top;
  float: left;
}
.zetta-menu .zm-row .zm-col > .zm-grid-wrapper {
  padding: 15px 20px;
  border-radius: 5px;
  background-color:none; 
  color: #354a5f;
}
.zetta-menu .zm-row .zm-col.c-12 {
  width: 100%;
}
.zetta-menu .zm-row .zm-col.c-11 {
  width: 91.66666666666667%;
}
.zetta-menu .zm-row .zm-col.c-10 {
  width: 83.33333333333334%;
}
.zetta-menu .zm-row .zm-col.c-9 {
  width: 75%;
}
.zetta-menu .zm-row .zm-col.c-8 {
  width: 66.66666666666667%;
}
.zetta-menu .zm-row .zm-col.c-7 {
  width: 58.333333333333336%;
}
.zetta-menu .zm-row .zm-col.c-6 {
  width: 50%;
}
.zetta-menu .zm-row .zm-col.c-5 {
  width: 41.66666666666667%;
}
.zetta-menu .zm-row .zm-col.c-4 {
  width: 33.333333333333336%;
}
.zetta-menu .zm-row .zm-col.c-3 {
  width: 25%;
}
.zetta-menu .zm-row .zm-col.c-2 {
  width: 16.666666666666668%;
}
.zetta-menu .zm-row .zm-col.c-1 {
  width: 8.333333333333334%;
}
.zetta-menu .w-1000 {
  width: 1000px;
}
.zetta-menu .w-950 {
  width: 950px;
}
.zetta-menu .w-900 {
  width: 900px;
}
.zetta-menu .w-850 {
  width: 850px;
}
.zetta-menu .w-800 {
  width: 800px;
  z-index:9999999999999999999999999999999999;
}
.zetta-menu .w-750 {
  width: 750px;
}
.zetta-menu .w-700 {
  width: 700px;
}
.zetta-menu .w-650 {
  width: 650px;
}
.zetta-menu .w-600 {
  width: 600px;
}
.zetta-menu .w-550 {
  width: 550px;
}
.zetta-menu .w-500 {
  width: 500px;
}
.zetta-menu .w-450 {
  width: 450px;
}
.zetta-menu .w-400 {
  width: 400px;
}
.zetta-menu .w-350 {
  width: 350px;
}
.zetta-menu .w-300 {
  width: 300px;
}
.zetta-menu .w-250 {
  width: 250px;
}
.zetta-menu .w-200 {
  width: 200px;
}
.zetta-menu .w-150 {
  width: 150px;
}
.zetta-menu .w-100 {
  width: 100px;
}
.zetta-menu .w-50 {
  width: 50px;
}
/*

====================== SEARCH PLACE ======================

*/
.zetta-menu .zm-search {
  position: relative;
  overflow: hidden;
}
.zetta-menu .zm-search form > label {
  position: absolute;
  cursor: text;
  left: 0;
  top: 0;
  padding: 0 20px;
  line-height: 50px;
}
.zetta-menu .zm-search input {
  height: 50px;
  padding: 0 27.5px;
  font-size: 11px;
  width: 0;
  opacity: 0.01;
  color: #FFF;
}
.zetta-menu .zm-search input:focus {
  width: 120px;
  opacity: 1;
}
.zetta-menu .zm-search input:focus + label {
  display: none;
}
.zetta-menu .zm-search input:focus,
.zetta-menu .zm-search input:hover {
  background-color:#f9c022; 
  color:#FFF;
}
.zetta-menu .zm-search:hover {
  background-color:none; 
  color:#a58d6b;
}
.zetta-menu.zm-position-right .zm-search,
.zetta-menu.zm-position-left .zm-search {
  float: none;
}
.zetta-menu.zm-position-right .zm-search input,
.zetta-menu.zm-position-left .zm-search input {
  width: 100%;
}
/*

====================== SHOW EFFECTS ======================

*/
/* fade */
.zetta-menu.zm-effect-fade > li > div,
.zetta-menu.zm-effect-fade li > ul {
  display: block;
  visibility: hidden;
  opacity: 0;
  -moz-transition: opacity .4s ease, visibility 0s linear 0s;
  transition: opacity .4s ease, visibility 0s linear 0s;
}
.zetta-menu.zm-effect-fade > li:hover > div,
.zetta-menu.zm-effect-fade li:hover > ul {
  visibility: visible;
  opacity: 1;
  -moz-transition: opacity .4s ease;
  transition: opacity .4s ease;
}
/* slide */
.zetta-menu.zm-effect-slide-top > li > div,
.zetta-menu.zm-effect-slide-top li > ul {
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}
.zetta-menu.zm-effect-slide-bottom > li > div,
.zetta-menu.zm-effect-slide-bottom li > ul {
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
}
.zetta-menu.zm-effect-slide-left > li > div,
.zetta-menu.zm-effect-slide-left li > ul {
  -webkit-transform: translateX(50px);
  -moz-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
}
.zetta-menu.zm-effect-slide-right > li > div,
.zetta-menu.zm-effect-slide-right li > ul {
  -webkit-transform: translateX(-50px);
  -moz-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  transform: translateX(-50px);
}
.zetta-menu.zm-effect-slide-top > li > div,
.zetta-menu.zm-effect-slide-bottom > li > div,
.zetta-menu.zm-effect-slide-left > li > div,
.zetta-menu.zm-effect-slide-right > li > div,
.zetta-menu.zm-effect-slide-top li > ul,
.zetta-menu.zm-effect-slide-bottom li > ul,
.zetta-menu.zm-effect-slide-left li > ul,
.zetta-menu.zm-effect-slide-right li > ul {
  display: block !important;
  visibility: hidden;
  opacity: 0;
  -moz-transition: opacity 0.4s ease, -moz-transform 0.4s ease, visibility 0.4s linear 0s;
  transition: opacity .4s ease, -webkit-transform .4s ease, visibility .4s linear 0s;
  transition: opacity .4s ease, transform .4s ease, visibility .4s linear 0s;
}
.zetta-menu.zm-effect-slide-top > li:hover > div,
.zetta-menu.zm-effect-slide-bottom > li:hover > div,
.zetta-menu.zm-effect-slide-left > li:hover > div,
.zetta-menu.zm-effect-slide-right > li:hover > div,
.zetta-menu.zm-effect-slide-top li:hover > ul,
.zetta-menu.zm-effect-slide-bottom li:hover > ul,
.zetta-menu.zm-effect-slide-left li:hover > ul,
.zetta-menu.zm-effect-slide-right li:hover > ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  -moz-transition: opacity 0.4s ease, -moz-transform 0.4s ease;
  transition: opacity .4s ease, -webkit-transform .4s ease;
  transition: opacity .4s ease, transform .4s ease;
}
/*

====================== RESPONSIVE ======================*/

@media screen and (max-width: 930px) {
.zetta-menu > li {
  height: 51px;
}
.zetta-menu.zm-full-width {
  display: block;
  float:none;
}
  /* ====================== MAIN ====================== */
.zetta-menu img{display:none;}
  
  .zetta-menu .ativo {
	  border-top-left-radius: 0px;
	}
  .zetta-menu > li:hover,
  .zetta-menu > li.zm-active {
}
  
  .zetta-menu[class*='zm-response'] > li {
    position: relative;
  }
  .zetta-menu[class*='zm-response'] > li > ul,
  .zetta-menu[class*='zm-response'] > li > div.zm-multi-column > ul,
  .zetta-menu[class*='zm-response'] > li > ul ul,
  .zetta-menu[class*='zm-response'] > li > div.zm-multi-column > ul ul {
    left: 0;
    right: 0;
    width: auto !important;
    margin-right: 0;
	display:none;
  }
  .zetta-menu[class*='zm-response'] > li > ul ul,
  .zetta-menu[class*='zm-response'] > li > div.zm-multi-column > ul ul {
    top: 100%;
    margin-left: 50px !important;
  }
  .zetta-menu[class*='zm-response'].zm-position-bottom > li > ul ul,
  .zetta-menu[class*='zm-response'].zm-position-bottom > li > div.zm-multi-column > ul ul,
  .zetta-menu[class*='zm-response'].zm-position-bottom > li > ul ul.zm-drop-left,
  .zetta-menu[class*='zm-response'].zm-position-bottom > li > div.zm-multi-column > ul ul.zm-drop-left {
    left: 0;
    top: auto;
    bottom: 100%;
  }
  .zetta-menu[class*='zm-response'].zm-position-left > li > ul,
  .zetta-menu[class*='zm-response'].zm-position-left > li > div.zm-multi-column > ul,
  .zetta-menu[class*='zm-response'].zm-position-right > li > ul,
  .zetta-menu[class*='zm-response'].zm-position-right > li > div.zm-multi-column > ul {
    top: 100%;
    left: 0;
    right: 0;
  }
  .zetta-menu[class*='zm-response'].zm-position-left > li > ul ul,
  .zetta-menu[class*='zm-response'].zm-position-left > li > div.zm-multi-column > ul ul,
  .zetta-menu[class*='zm-response'].zm-position-right > li > ul ul,
  .zetta-menu[class*='zm-response'].zm-position-right > li > div.zm-multi-column > ul ul,
  .zetta-menu[class*='zm-response'].zm-position-left > li > ul ul.zm-drop-left,
  .zetta-menu[class*='zm-response'].zm-position-left > li > div.zm-multi-column > ul ul.zm-drop-left,
  .zetta-menu[class*='zm-response'].zm-position-right > li > ul ul.zm-drop-left,
  .zetta-menu[class*='zm-response'].zm-position-right > li > div.zm-multi-column > ul ul.zm-drop-left {
    left: 0;
    right: 0;
    top: 100%;
  }
  .zetta-menu[class*='zm-response'].zm-position-left > li.zm-content > div,
  .zetta-menu[class*='zm-response'].zm-position-left > li.zm-content-full > div,
  .zetta-menu[class*='zm-response'].zm-position-right > li.zm-content > div,
  .zetta-menu[class*='zm-response'].zm-position-right > li.zm-content-full > div {
    top: 100%;
    left: 0;
    right: 0;
  }
  .zetta-menu[class*='zm-response'] li > div {
    top: 100%;
    left: 0 !important;
    right: 0;
    width: auto !important;
  }
  .zetta-menu[class*='zm-response'] li > div.zm-multi-column > ul {
    width: 100% !important;
  }
  .zetta-menu[class*='zm-response'] .zm-row .zm-col {
    display: block;
    margin-right: 0;
    width: 100% !important;
    float: none;
  }
  .zetta-menu[class*='zm-response'] .w-50,
  .zetta-menu[class*='zm-response'] .w-100,
  .zetta-menu[class*='zm-response'] .w-150,
  .zetta-menu[class*='zm-response'] .w-200,
  .zetta-menu[class*='zm-response'] .w-250,
  .zetta-menu[class*='zm-response'] .w-300,
  .zetta-menu[class*='zm-response'] .w-350,
  .zetta-menu[class*='zm-response'] .w-400,
  .zetta-menu[class*='zm-response'] .w-450,
  .zetta-menu[class*='zm-response'] .w-500,
  .zetta-menu[class*='zm-response'] .w-550,
  .zetta-menu[class*='zm-response'] .w-600,
  .zetta-menu[class*='zm-response'] .w-650,
  .zetta-menu[class*='zm-response'] .w-700,
  .zetta-menu[class*='zm-response'] .w-750,
  .zetta-menu[class*='zm-response'] .w-800,
  .zetta-menu[class*='zm-response'] .w-850,
  .zetta-menu[class*='zm-response'] .w-900,
  .zetta-menu[class*='zm-response'] .w-950,
  .zetta-menu[class*='zm-response'] .w-1000 {
    width: 100%;
  }
  /* ====================== SIMPLE ====================== */
  .zetta-menu.zm-response-simple > li {
    position: static;
  }
  .zetta-menu.zm-response-simple > li > a {
    font-size: 0;
  }
  .zetta-menu.zm-response-simple > li > a i {
    letter-spacing: 0;
  }
  .zetta-menu.zm-response-simple > li > a .zm-caret {
    display: none;
  }
  .zetta-menu.zm-response-simple > li.zm-logo {
    display: none;
  }
  .zetta-menu.zm-response-simple > li.zm-search {
    position: relative;
  }
  /* ====================== STACK ====================== */
  .zetta-menu.zm-response-stack > li {
    display: block;
    float: none;
  }
  .zetta-menu.zm-response-stack > li.zm-content-full {
    position: relative;
  }
  .zetta-menu.zm-response-stack > li.zm-logo {
    text-align: center;
  }
  .zetta-menu.zm-response-stack > li.zm-logo img {
    position: relative;
  }
  .zetta-menu.zm-response-stack > li.zm-logo,
  .zetta-menu.zm-response-stack > li.zm-right-item {
    position: relative;
    width: 100%;
  }
  .zetta-menu.zm-response-stack > li > a .zm-caret {
    float: right;
  }
  .zetta-menu.zm-response-stack .zm-search {
    float: none;
  }
  .zetta-menu.zm-response-stack .zm-search input {
    width: 100%;
  }
  /* ====================== SWITCH ====================== */
  .zetta-menu.zm-response-switch > li {
    display: none;
    float: none;
	background: #222;
        border-bottom: 1px dotted #000;
  }
    .zetta-menu.zm-response-switch > li.zm-logo {
    display:inherit;
	text-align:left;
	line-height:50px;
	text-indent:15px;
	font-size:14px;
	font-weight:bold;
		/*background-color: rgba(0, 0, 0, 0.8) !important;*/
  color:#fff !important
	
  }
  
  .zetta-menu.zm-response-switch > li.zm-content-full {
    position: relative;
  }
  .zetta-menu.zm-response-switch > li.zm-logo {
    display: block;
  }
  .zetta-menu.zm-response-switch > li.zm-logo img {
    position: relative;
    z-index: 1;
  }
  .zetta-menu.zm-response-switch > li.zm-logo,
  .zetta-menu.zm-response-switch > li.zm-right-item {
    position: relative;
    width: 100%;
	color:#fff !important
  }
  .zetta-menu.zm-response-switch:before {
	  color:#FFF !important;
    font-family: FontAwesome;
	font-size:18px;
    content: '\f0c9';
    float: right;
    cursor: pointer;
    line-height: 50px;
    height: 50px;
    padding: 0 20px;
    z-index: 1;
	color:#FFF !important;
  }
  .zetta-menu.zm-response-switch:hover:before {
    display: none;
  }
  .zetta-menu.zm-response-switch:hover > li {
    display: block;
  }
  .zetta-menu.zm-response-switch > li > a .zm-caret {
    float: right;
  }
  .zetta-menu.zm-response-switch .zm-search {
    float: none;
  }
  .zetta-menu.zm-response-switch .zm-search input {
    width: 100%;
  }
  .zetta-menu,
  .zetta-menu a,
  .zetta-menu a:visited {
  font-size:11px;
  color: #FFFFFF;
  }
  .zetta-menu a:hover {

  }
  .zetta-menu.zm-full-width {
  display: block;
  background-color: #333;
}

.zetta-menu,
.zetta-menu a,
.zetta-menu a:visited {
      line-height: 49px !important;
}
}
